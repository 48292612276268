// media queries
let viewportWidth = getScreenSize();
const mediaBreakpoint = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1366,
};
let ranges = [];
let rentCalc = {};
function CalcRent(hourPrice, palcePrice, hours = 2) {
	this.hourPrice = parseFloat(hourPrice);
	this.placePrice = parseFloat(palcePrice),
	this.hours = parseFloat(hours);

	this.changeValue = function(name, value) {
		let parseValue = parseFloat(value);

		if (!this.hasOwnProperty(name)) {
			console.error("Свойства с таким именем нет!");
			return;
		}

		if (!isNaN(parseValue) || isFinite(parseValue)) {
			this[name] = parseValue;
		} else {
			console.error("Необходимо передать строку с числом!");
			return;
		}
	};

	this.getCost = function () {
		return this.hours * this.hourPrice + this.placePrice;
	}
};

;(function(){
	var pathElem = document.querySelector(".preloader__border");
	var SVGRoadInstance = new SVGRoad(pathElem);

	SVGRoadInstance.setStrokeDasharrayInPercent(100);
	SVGRoadInstance.setStrokeDashoffsetInPercent(-6000);
})();


$(function () {

	$('.wrap-site').css({
		'display': 'flex'
	});
	// preloader
	$('html').removeClass('not-loaded')
	setTimeout(function() {AOS.init()}, 600);

	objectFitFallback( $("[data-object-fit]") );

	{
		const header = document.querySelector("header")
		const headroom  = new Headroom(header, {
			offset: header.offsetTop
		})
		headroom.init();
	}

	// mask for input tel
	$(".js-tel").mask("+7 (999) 999-99-99", { placeholder: "+7 (___) ___-__-__" });

	$('.js-tabs').responsiveTabs({
		startCollapsed: 'accordion',
		active: 0,
	});

	$('.top-block__slider').on('init reInit afterChange', function (event, slick, currentSlide) {
		const index = (currentSlide ? currentSlide : 0)
		$('.top-block__btn').each(function (i, item) {
			if (index == i) {
				item.classList.remove('d-none')
			} else {
				item.classList.add('d-none')
			}
		});

		slick.$slider.next()
			.find('.slick-paging__current').text(index + 1).end()
			.find('.slick-paging__all').text(slick.slideCount)
	}).on('lazyLoaded', function(event, slick, image, imageSource){
		objectFitFallback( $("[data-object-fit]", slick.$slider) )
	}).slick({
		infinite: true,
		touchThreshold: 10,
		lazyLoad: 'progressive',
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		arrows: true,
		dots: true,
		appendDots: $('.js-top-slider-dots'),
		cssEase: 'ease-in-out'
	})

	$('.partners__slider').slick({
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
		swipeToSlide: true,
		touchThreshold: 10,
		dots: false,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		pauseOnFocus: false,
		responsive: [
		{
			breakpoint: mediaBreakpoint.md,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: mediaBreakpoint.lg,
			settings: {
				slidesToShow: 3,
			}
		}
		]
	})

	$('.staff-slider').slick({
		infinite: true,
		slidesToShow: 3,
		swipeToSlide: true,
		touchThreshold: 10,
		centerMode: true,
		centerPadding: 0,
		focusOnSelect: true,
		arrows: true,
		dots: false,
		responsive: [
		{
			breakpoint: mediaBreakpoint.md,
			settings: {
				slidesToShow: 1,
			}
		},
		{
			breakpoint: mediaBreakpoint.lg,
			settings: {
				slidesToShow: 1,
				centerPadding: '160px',
			}
		}
		]
	})

	$('.hall__slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
		let i = (currentSlide ? currentSlide : 0) + 1
		slick.$slider.next()
		.find('.slick-paging__current').text(i).end()
		.find('.slick-paging__all').text(slick.slideCount)
	}).slick({
		infinite: true,
		touchThreshold: 10,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		arrows: true,
		dots: true,
		cssEase: 'ease-in-out',
		responsive: [
		{
			breakpoint: mediaBreakpoint.xl,
			settings: {
				adaptiveHeight: true,
			}
		}
		]
	})

	{
		$('.equipment__slider').slick({
			infinite: true,
			slidesToShow: 1,
			fade: true,
			dots: false,
		})

		$(document).on('click', '.equipment__item-link', function(e) {
			e.preventDefault()
			const $t = $(this)
			const id = $t.attr('data-id')

			if (!$t.parent().hasClass('active')) {
				$('.equipment__item').removeClass('active')
				$t.parent().addClass('active')

				$('.equipment__text').fadeOut(0)
				$t.closest('.equipment').find('[data-content-id='+id+']').fadeIn(0)
			} else {
				$t.parent().removeClass('active')
				$('.equipment__text').fadeOut(200)
			}
		})
	}

	$('.js-grid').masonry({
		columnWidth: '.grid-sizer',
		itemSelector: '.grid-item',
		percentPosition: true
	});

	// $('.js-date-input').datepicker({
	// 	minDate: new Date(),
	// });

	// modals
	var modalDatepicker = null;
	$.arcticmodal('setDefault', {
		closeOnOverlayClick: false,
		overlay: {
			css: {
				backgroundColor: '#EFEFEF',
				opacity: 0.85
			}
		},
		openEffect: {
			speed: 200
		},
		closeEffect: {
			speed: 200
		},
		afterOpen: function(data, el) {
			let popupBlock = $(data.container.block[0]),
				today = new Date();

			today.setDate(today.getDate() + 1);

			if (popupBlock.find('.js-review-popup').length) {
				$('.js-avatar-load').on('change', uploadImageShow);
				return;
			}
			
			if (popupBlock.find('.js-rent-popup').length) {

				let placePrice = popupBlock.find('.js-rent-place:checked').data('price'),
					hourPrice = popupBlock.find('.js-rent-hour-price').data('price'),
					hours = popupBlock.find('.js-range-rent-time').data('min');

				rentCalc = new CalcRent(hourPrice, placePrice, hours);

				popupBlock.find('.js-range').each(function() {
					initRange(this);
				});

				popupBlock.find('.js-rent-place').on('change', function(e) {
					let data = $(this).data();
					rentCalc.changeValue('placePrice', data.price);
					changeRentCost();
					ranges.filter(function(item) {
						return item.classList.contains('js-range-rent-people');
					}).forEach(function(item) {
						item.noUiSlider.updateOptions({
							range: {
								min: 1,
								max: data.maxPeople
							}
						}, true);
					});
				});

				if ($('.js-date-input').length) {
					modalDatepicker = $('.js-date-input').datepicker({
						minDate: today,
						autoClose: true
					}).data('datepicker');
				}

				$('.js-hide-popup-toggle').on('click', function(e) {
					let toggleBlock = $(this);
					if (toggleBlock.hasClass('active')) {
						toggleBlock.siblings('.js-hide-popup-block').slideUp('slow');
					} else {
						toggleBlock.siblings('.js-hide-popup-block').slideDown('slow');
					}
					$(this).toggleClass('active');
				});
			}
		},
		beforeClose: function(data, el) {
			let popupBlock = $(data.container.block[0]);

			if (popupBlock.find('.js-review-popup').length) {
				$('.js-avatar-load').off('change', uploadImageShow);
				return;
			}

			if (modalDatepicker) {
				modalDatepicker.destroy();
				modalDatepicker = null;
			}

			if (popupBlock.find('.js-rent-popup').length) {
				$('.js-hide-popup-toggle').off('click');
				popupBlock.find('.js-rent-place').off('change');
				rentCalc = {};
				
				while (ranges.length) {
					let range = ranges.pop();
					range.noUiSlider.destroy();
				}
			}
		}
		// beforeOpen: function(data, el) {
		// 	$('.header').css('paddingRight', getScrollbarSize())
		// },
		// afterClose: function(data, el) {
		// 	$('.header').removeAttr('style')
		// },
	});

	function initRange(slider) {
		const min = parseFloat(slider.getAttribute('data-min'));
		const startMin = parseFloat(slider.getAttribute('data-start-min'));
		const max = parseFloat(slider.getAttribute('data-max'));
		const step = parseInt(slider.getAttribute('data-step'));
		const input = slider.parentElement.querySelector('.js-range-value');

		noUiSlider.create(slider, {
			start: [startMin],
			connect: true,
			step: step,
			range: {
				'min': min,
				'max': max
			},
			tooltips: [true],
			format: wNumb({
				decimals: step > 0 ? 0 : 1
			})
		});

		ranges.push(slider);

		// ranges[0].noUiSlider.updateOptions({range: {min: 1, max: 35}}, true)

		if (input) {
			slider.noUiSlider.on('update', function(values, handle) {
				input.value = values[handle];

				if (this.target.classList.contains('js-range-rent-time')) {
					changeRentTime(parseInt(values[handle]));
					changeRentCost();
				}
			});
		}
	};

	function changeRentCost() {
		let costString = document.querySelector('.js-rent-cost'),
			stringValues = costString.innerText.split(' ');

		stringValues[0] = rentCalc.getCost();

		costString.innerText = stringValues.join(' ');
	}

	function changeRentTime(value) {
		let resultString = document.querySelector('.js-rent-hours'),
			stringValues = resultString.innerText.split(' ');

		rentCalc.changeValue('hours', value);

		stringValues[0] = value;
		stringValues[1] = numString(value, ['час', 'часа', 'часов']);
		stringValues[2] = value * rentCalc.hourPrice;

		resultString.innerText = stringValues.join(' ');
	}

	function numString(number, strings) {
		let number1 = number % 10;
		if (number > 10 && number < 20) { return strings[2]; }
		if (number1 > 1 && number1 < 5) { return strings[1]; }
		if (number1 == 1) { return strings[0]; }
		return strings[2];
	}

	$(document).on('click', '.ajax-popup', function (e) {
		e.preventDefault();
		$.arcticmodal('close');
		let url = $(this).attr('href');

		$.arcticmodal({
			type: 'ajax',
			url: url,
		});
	});

	$(document).on('click', '[data-popup-gallery]', function (e) {

		e.preventDefault();

		$.arcticmodal('close');

		const $t = $(this)
		let index
		const galleryName = $t.attr('data-popup-gallery')
		const src = $t.attr('href')
		const caption = $t.attr('data-popup-caption')
		let activeType = $t.attr('title')

		const galleryActions = ($el, images, currentIndex) => {
			const $popup = $el
			const pics = images
			const picsCount = images.length
			const $img = $('img', $popup)
			const $iframe = $('iframe', $popup)
			const $caption = $('.popup__img-caption', $popup)
			let i = currentIndex

			$popup.on('click', '.popup__btn-prev', () => {
				$popup.addClass('popup--loading')
				i == 0 ? i = (picsCount-1) : i--
				let type = pics[i].title;

				if (activeType !== type) {
					switch(activeType) {
						case 'image':
							$img.attr('src', '').hide();
							$iframe.show();
							break;
						case 'video':
							$iframe.attr('src', '').hide();
							$img.show();
							break;
					}
				}

				activeType = type;

				switch(type) {
					case 'image':
						$img.attr('src', pics[i].src).load(function () {
							$popup.removeClass('popup--loading')
							$caption.text(pics[i].caption)
							.css('width', $(this).width())
						})
						break;
					case 'video':
						$iframe.attr('src', pics[i].src).load(function () {
							$popup.removeClass('popup--loading')
							$caption.text(pics[i].caption)
							.css('width', $(this).width())
						})
						break;
				}
			})
			$popup.on('click', '.popup__btn-next, img', () => {
				$popup.addClass('popup--loading')
				i == (picsCount-1) ? i = 0 : i++
				let type = pics[i].title;

				if (activeType !== type) {
					switch(activeType) {
						case 'image':
							$img.attr('src', '').hide();
							$iframe.show();
							break;
						case 'video':
							$iframe.attr('src', '').hide();
							$img.show();
							break;
					}
				}

				activeType = type;

				switch(type) {
					case 'image':
						$img.attr('src', pics[i].src).load(function () {
							$popup.removeClass('popup--loading')
							$caption.text(pics[i].caption)
							.css('width', $(this).width())
						})
						break;
					case 'video':
						$iframe.attr('src', pics[i].src).load(function () {
							$popup.removeClass('popup--loading')
							$caption.text(pics[i].caption)
							.css('width', $(this).width())
						})
						break;
				}
			})
		}

		let images = []
		$('[data-popup-gallery='+galleryName+']').each(function (i) {
			const $t = $(this);
			const url = $t.attr('href')
			const equal = src.localeCompare(url) ? false : true
			if (equal) { index = i }
			images.push({
				'src': url,
				'title': $t.attr('title'),
				'caption': $t.attr('data-popup-caption'),
			});
		})

		$('#popupImage').arcticmodal({
			beforeOpen: function(data, el) {
				const $popup = $(el);

				galleryActions($popup, images, index)

				$('.header').css('paddingRight', getScrollbarSize())

				$popup.addClass('popup--loading')
				.closest('.arcticmodal-container').addClass('arcticmodal-img')

				switch(activeType) {
					case 'image':
						$('iframe', $popup).hide()
						$('img', $popup).attr({
							'src': src,
							'style': 'max-height:'+($(window).height() -170 )+'px'
						}).load(function () {
							$popup.removeClass('popup--loading')
							$('.popup__img-caption', $popup).text(caption)
							.css('width', $(this).width())
						});
						break;
					case 'video':
						$('img', $popup).hide()
						$('iframe', $popup).attr({
							'src': src,
						}).load(function () {
							$popup.removeClass('popup--loading')
							$('.popup__img-caption', $popup).text(caption)
							.css('width', $(this).width())
						});
						break;
				}
			}
		})
	});

	spoiler('.b-price__header', '.b-price__body', 200)
	if (viewportWidth < mediaBreakpoint.xl) {
		spoiler('.tab-price__header', '.tab-price__content', 200)
	} else {
		$('.tab-price__content').each(function(){
			const $t = $(this)
			$t.closest('.b-price__body').append($t.detach())
		})
		$(document).on('click', '.tab-price:not(.active)', function() {
			$(this)
			.addClass('active').siblings().removeClass('active')
			.closest('.b-price__body')
			.find('.tab-price__content').removeClass('active')
			.eq($(this).index()).addClass('active')
			;
		});
	}

	spoiler('.r-category__header', '.r-category__list', 200)

	$(document).on('click', '.menu-trigger', function(){
		$('body').toggleClass('nav-showed')
	});

	$(document).on('click', '.js-animated-link', function(e) {
		e.preventDefault()
		const scrollBarSize = getScrollbarSize()
		$('html').addClass('not-loaded')
		if (scrollBarSize) {
			$('body').css('paddingRight', scrollBarSize)
			$('.header').css('paddingRight', scrollBarSize)
		}
		setTimeout(_=> {
			window.location = $(this).attr('href')
		}, 600);
	});

	// close on focus lost
	$(document).click(function(e) {
		const $trg = $(e.target);
		if (!$trg.closest(".parent-element").length && !$trg.hasClass('trigger-class') || $trg.hasClass('close-btn')) {
			$('.block').removeClass('active');
			$('.spoiler').slideUp(200);
		}
	});

});

// content page
$(function () {
	$('.js-content-images').find('img').each(function () {
				var $t = $(this);
				$t.wrap('<div class="content-img"></div>');
				if ($t.css('float') == 'left' || $t.attr('align') == 'left') {
						$t.parent().addClass('content-img--left');
				} else if ($t.css('float') == 'right' || $t.attr('align') == 'right') {
						$t.parent().addClass('content-img--right');
				}
		});
	$('.content table').each(function () {
		if($(this).find('th').length) $(this).addClass('styled-table');
	});
	$('.styled-table').each(function(){
		const $t = $(this)
		const $th = $t.find('tr:first').children()
		const rows = $t.find('tr')
		let tHeaders = []
		for (let i = 0; i < $th.length; i++) {
			tHeaders.push($($th[i]).text())
		}
		for (let i = 1; i < rows.length; i++) {
			const $tds = $(rows[i]).find('td')
			$tds.each(function(i, item){
				$(item).attr('data-th', tHeaders[i])
			})
		}
	})
})

// top slider text fix
$(function () {
	const contentWidth = $('.top-slider__content').width()
	$('.top-slider__item').each(function (i, item) {
		const $slide = $(item)
		const textWidth = $slide.find('.top-slider__text span').width()
		if (textWidth < contentWidth) {
			$('.top-slider__content', $slide).addClass('top-slider__content--full')
		}
	})
})

$( window ).on( "load", function() {
	$(".dot").dotdotdot({watch: "window"});

	if ($('#mapit').length && window.google) {
		mapInit()
	}
})

function uploadImageShow(e) {
	let files = this.files,
		imgBlock = this.parentElement.querySelector('.js-avatar-img');

	if(files !== undefined && files.length > 0) {
		let reader = new FileReader();
		reader.onload = (function(file) {
			return function(e) {
				if (imgBlock) {
					let img = imgBlock.querySelector('img');
					img.src = e.target.result;
				}
			};
		})(files[0]);

		// Read in the image file as a data URL.
		reader.readAsDataURL(files[0]);
	}
}

function mapInit() {
	const mapElem = document.getElementById('mapit');
	const inlineData = $(mapElem).data();
	const mapOptions = {
		center: {
			lat: inlineData.lat,
			lng: inlineData.lng
		},
		zoom: 16,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		disableDefaultUI: true,
		styles: [{"featureType":"all","elementType":"all","stylers":[{"hue":"#ff0000"},{"saturation":-100},{"lightness":-30}]},{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#353535"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#656565"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#505050"}]},{"featureType":"poi","elementType":"geometry.stroke","stylers":[{"color":"#787878"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#454545"}]},{"featureType":"transit","elementType":"labels","stylers":[{"saturation":100},{"lightness":-40},{"invert_lightness":true},{"gamma":1.5},{"color":"#e94c3d"}]},{"featureType":"transit.station","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"44"},{"lightness":"-28"},{"hue":"#ff5c00"}]},{"featureType":"transit.station","elementType":"geometry.fill","stylers":[{"saturation":"-6"},{"color":"#c27c7c"}]},{"featureType":"transit.station","elementType":"geometry.stroke","stylers":[{"saturation":"0"},{"lightness":"8"},{"color":"#ae5252"}]},{"featureType":"transit.station","elementType":"labels.text.fill","stylers":[{"lightness":"5"},{"color":"#e94c3d"}]},{"featureType":"transit.station","elementType":"labels.text.stroke","stylers":[{"weight":"2.51"},{"color":"#ffd5d1"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"color":"#555555"}]}]

	};
	const map = new google.maps.Map(mapElem, mapOptions);
	const contentString = ''
	+'<div class="map-info">'
	+'<div>'+inlineData.title+'</div>'
	+'</div>'
	;
	const infowindow = new google.maps.InfoWindow({
		content: contentString
	});
	const marker = new google.maps.Marker({
		position: new google.maps.LatLng(inlineData.lat, inlineData.lng),
		map: map,
		icon: inlineData.marker,
		title: inlineData.title
	});
	marker.addListener('click', function() {
		infowindow.open(map, marker);
	});
}

function getScreenSize() {
	let screenSize =
	window
	.getComputedStyle(document.querySelector('body'), ':after')
	.getPropertyValue('content')
	screenSize = parseInt(screenSize.match(/\d+/));
	return screenSize;
}

function getScrollbarSize() {
	let scrollbarSize = undefined;
	if(scrollbarSize === undefined) {
		var scrollDiv = document.createElement("div");
		scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
		document.body.appendChild(scrollDiv);
		scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
	}
	return scrollbarSize;
}


function spoiler(spoilerTitle, spoilerBody, time) {
	const activeClass = 'active';
	let $titles = $(spoilerTitle);
	let $bodys = $(spoilerBody);

	$bodys.slideUp(0);
	$titles.first().addClass(activeClass)
	.next().slideToggle(0);
	$(document).on('click', spoilerTitle, function() {
		let $t = $(this);
		if ( !$t.hasClass(activeClass) && viewportWidth >= mediaBreakpoint.md ) {
			$titles.removeClass(activeClass);
			$bodys.slideUp(time);
			$t.toggleClass(activeClass)
			.next().slideToggle(time);
		} else{
			$t.toggleClass(activeClass)
			.next().slideToggle(time);
		}
	})
}

function SVGRoad(elem) {
	this.pathLength = elem.getTotalLength();
	this.setStrokeDasharrayInPercent = function() {
		var strokeDasharray = "";
		for (let i = 0; i < arguments.length; i++) {
			strokeDasharray += arguments[i] / 100 * this.pathLength + " ";
		}
		elem.style.strokeDasharray = strokeDasharray;
	};

	this.setStrokeDashoffsetInPercent = function(strokeDashoffset) {
		elem.style.strokeDashoffset = strokeDashoffset / 100 * this.pathLength;
	};
}

function objectFitFallback (selector) {
	// if (true) {
	if ('objectFit' in document.documentElement.style === false) {
		for (var i = 0; i < selector.length; i++) {
			const that = selector[i]
			const imgUrl = that.getAttribute('src') ? that.getAttribute('src') : that.getAttribute('data-src');
			const dataFit = that.getAttribute('data-object-fit')
			let fitStyle
			if (dataFit === 'cover') {
				fitStyle = 'cover'
			} else {
				fitStyle = 'contain'
			}
			const parent = that.parentElement
			if (imgUrl) {
				parent.style.backgroundImage = 'url(' + imgUrl + ')'
				parent.classList.add('fit-img')
				parent.classList.add('fit-img--'+fitStyle)
			}
		};
	}
};

var anchors = Array.from(document.querySelectorAll('a[href*="#"]'));

if (anchors.length) {
	anchors.forEach(function(anchor) {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			
			var blockID = anchor.getAttribute('href').substr(1);
			
			document.getElementById(blockID).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		})
	});
}